import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageBaconsLoppyJoe = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/baconsloppyjoe_large.jpg';
const RecipePage = () => (
    <Layout metaTitle="Bacon Grilled Cheese Sandwiches"
        metaDescription="This meaty sloppy joe packed full of our smoky bacon & smothered on top of a grilled cheese sandwich packed full of bacon makes us rethink our stance on sloppiness."
        metaKeywords="sloppy joe recipe"
    >
        <meta property="og:image" content={imageBaconsLoppyJoe} />
        <link rel="image_src" href={imageBaconsLoppyJoe} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageBaconsLoppyJoe} class='img-responsive' alt='Bacon Sloppy Joe' />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Bacon Grilled Cheese Sandwiches smothered with Bacon Sloppy Joe</h1>

                            <div class="recipe-content">
                                <p >We take pride in our painstakingly prepared slices of thick-cut bacon. We don&rsquo;t normally do sloppy. However, this meaty sloppy joe packed full of our smoky bacon and smothered on top of a grilled cheese sandwich, also packed full of bacon, makes us rethink our stance on sloppiness.</p>
                                <p><br />
                                </p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        10&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        35&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p> </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                12 <span class="uom"></span> slices Wright® Brand Naturally Hickory Smoked Bacon, divided
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom"></span> red bell pepper, diced
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom"></span> medium onion, diced
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Pound</span> ground beef
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 12oz. <span class="uom">Can</span> sloppy joe sauce
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                8 <span class="uom"></span> slices white bread
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                8 <span class="uom"></span> slices American cheese
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                <span class="uom"></span> sour cream to taste
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Cook bacon until crispy. Allow to cool slightly, then crumble bacon into a bowl, and set aside.

                </p>
                            <p>
                                2. While bacon is cooking, sauté the peppers and onions in a large skillet over medium-high heat until the onions are golden brown.

                </p>
                            <p>
                                3. Add beef to the pepper and onion mixture. Stir until beef is thoroughly cooked.

                </p>
                            <p>
                                4. Add sloppy joe sauce and ¾ of the crumbled bacon to beef mixture, let simmer.

                </p>
                            <p>
                                5. Now for the grilled cheese sandwich. Place 4 slices of bread on a work surface. Top with one slice of cheese, ¼ of the remaining crumbled bacon and another slice of cheese. Top with remaining bread. Brush both sides of bread with melted butter. In a medium skillet over medium heat, cook each sandwich until browned on both sides.

                </p>
                            <p>
                                6. Place grilled cheese sandwiches on 4 individual plates. Top with sloppy joe mixture and a spoonful of sour cream.

                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
